// mainnet
export const TBTConsumpAddress = '0x34D8A12618f590C127F05E9fddFfded42685cCd3'
export const TBTAddress = '0xd7F97f2cBcDC696484dA7C04AD012723392Ce30B'
export const DefiAddress = '0xdC2C5A399aeFce5484B3E4c0eF2F9e671487403D';
export const TBTBankAddress = '0x76870a49E2d5cB24749Acf29AB21c3DF76c0F66B'
// export const rpcURL = 'https://bsc-dataseed.binance.org/';
export const rpcURL = 'https://bsc-dataseed1.defibit.io/';

// // testnet
// export const TBTConsumpAddress = '0x7Dd49B4f2577eE9BBC1EAFAE4D21e2555D7e84bf'
// export const rpcURL = 'https://data-seed-prebsc-1-s1.binance.org:8545';